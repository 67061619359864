function App() {
  return (
    <div className='flex items-center justify-center w-screen h-screen bg-blue-50 p-4'>
      <div className="card h-auto w-full xl:w-1/5 md:w-1/2 md:h-auto bg-white border-solid border border-gray-200 rounded p-4 relative">
        <div className="profile-image w-40 h-40 md:w-44 md:h-44 bg-white rounded-full border-2 border-gray-200 flex items-center justify-center absolute -top-16 md:-top-20 left-1/2 -translate-x-1/2 bg-center bg-cover bg-no-repeat bg-[url('/public/profile-linkedin.jpeg')]"></div>
        <div className="card-info mt-24">
          <h1 className="text-3xl text-purple-600 font-bold font-mono">Alireza Shahabi</h1>
          <p className="font-mono">Senior Software Engineer</p>
          <br />

          <p className="text-sm font-light">Experienced Software engineer with a passion for developing high-quality, scalable, and user-friendly applications. With 7+ years of experience in the industry, I have a deep understanding of front-end technologies and expertise in Node.js. In my most recent role, I also honed my skills in people management, successfully leading a team of 6 members. I'm always looking for opportunities to leverage my technical skills and passion for development to contribute to innovative projects.</p>
          <div className="flex items-center justify-center gap-10 mt-10">
            <a href="https://www.linkedin.com/in/shahabvshahabi1996/" target="_blank" rel="noopener noreferrer"><svg role="img" className="w-12 h-12 fill-[#0A66C2] border border-gray-100 rounded p-0.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg></a>
            <a href="https://medium.com/@shahabvshahabi1996" target="_blank" rel="noopener noreferrer"><svg role="img" className="w-12 h-12 fill-black border border-gray-100 rounded p-0.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Medium</title><path d="M13.54 12a6.8 6.8 0 01-6.77 6.82A6.8 6.8 0 010 12a6.8 6.8 0 016.77-6.82A6.8 6.8 0 0113.54 12zM20.96 12c0 3.54-1.51 6.42-3.38 6.42-1.87 0-3.39-2.88-3.39-6.42s1.52-6.42 3.39-6.42 3.38 2.88 3.38 6.42M24 12c0 3.17-.53 5.75-1.19 5.75-.66 0-1.19-2.58-1.19-5.75s.53-5.75 1.19-5.75C23.47 6.25 24 8.83 24 12z"/></svg></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
